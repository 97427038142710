// api.js
import axios from 'axios';

const baseURL = process.env.VUE_APP_API_BASE_URL || 'http://localhost:5005/api/';

const API = axios.create({ baseURL });

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('user-token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized. You do not have permission to perform this action.");
    }
    return Promise.reject(error);
  }
);

// Existing API methods
API.getRoles = () => API.get('/roles');
API.addRole = (data) => API.post('/roles', data);
API.deleteRole = (roleId) => API.delete(`/roles/${roleId}`);
API.updateRolePermissions = (data) => API.put('/roles/permissions', data);
API.getPermissions = () => API.get('/permissions');
API.getRolePermissions = (roleId) => API.get(`/permissions/${roleId}/permissions`);
API.assignPermission = (roleId, permissionId) => API.post('/permissions/assign', { roleId, permissionId });
API.unassignPermission = (roleId, permissionId) => API.post('/permissions/unassign', { roleId, permissionId });
API.getTeachers = () => API.get('/users/teachers');

// New API methods for cohort and children management
API.getChildren = () => API.get('/users/children');
API.addChildrenToCohort = (cohortId, childIds) => API.post(`/cohorts/${cohortId}/add-children`, { childIds });

export default API;
