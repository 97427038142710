// authService.js
import API from './api';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useGtm } from '@gtm-support/vue-gtm';

const AuthService = {
  getTenantHost() {
    const hostname_parts = window.location.hostname.split('.').reverse();
    if (hostname_parts.length >= 2 && hostname_parts[1] === 'schoolpeak') {
      return hostname_parts[2];
    } else {
      return 'tenant1';
    }
  },
  async login(email, password) {
    if (!email || !password) {
      throw new Error('Email and password are required');
    }

    try {
      const gtm = useGtm();
      const tenantId = this.getTenantHost();
      console.log('Tenant ID:', tenantId);
      const response = await API.post('auth/login', {
        email,
        password,
        tenantId,
      });

      console.log('Login response:', response.data);

      if (!response.data._id || !response.data.token || !response.data.roles) {
        throw new Error('Incomplete response from server');
      }

      const roles = response.data.roles;
      const roleIds = response.data.roleIds || [];
      const childRoleId = response.data.childRoleId;

      console.log('Fetched roles:', roles);
      console.log('Fetched roleIds:', roleIds);

      localStorage.setItem('user-token', response.data.token);
      console.log('user-token saved:', response.data.token);

      localStorage.setItem('user-roles', JSON.stringify(roles));
      console.log('user-roles saved:', JSON.stringify(roles));

      localStorage.setItem('user-id', response.data._id);
      console.log('user-id saved:', response.data._id);

      if (roleIds.length === 0) {
        console.warn('No roleIds returned from the server.');
      }

      localStorage.setItem('role-ids', JSON.stringify(roleIds));
      console.log('role-ids saved:', JSON.stringify(roleIds));

      localStorage.setItem('child-role-id', childRoleId);
      console.log('child-role-id saved:', childRoleId);

      if (roles.some((role) => role === 'parent')) {
        localStorage.setItem('parent-id', response.data._id);
        console.log('parent-id saved:', response.data._id);
      }

      console.log('Current localStorage content:');
      console.log('user-token:', localStorage.getItem('user-token'));
      console.log('user-roles:', localStorage.getItem('user-roles'));
      console.log('user-id:', localStorage.getItem('user-id'));
      console.log('role-ids:', localStorage.getItem('role-ids'));
      console.log('child-role-id:', localStorage.getItem('child-role-id'));
      console.log('parent-id:', localStorage.getItem('parent-id'));

      gtm.trackEvent({
        event: 'login',
        user_id: response.data._id,
        tenant_id: tenantId,
      });

      return { roles, roleIds, childRoleId };
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.message || 'Login failed');
      } else {
        throw new Error('Network error or server is not responding');
      }
    }
  },

  async register(
    username,
    email,
    password,
    roles,
    firstName,
    lastName,
    address,
    phone
  ) {
    if (
      !username ||
      !email ||
      !password ||
      !roles ||
      !firstName ||
      !lastName ||
      !address ||
      !phone
    ) {
      throw new Error('All fields are required');
    }

    try {
      const tenantId = this.getTenantHost();
      const response = await API.post('auth/register', {
        username,
        email,
        password,
        roles,
        firstName,
        lastName,
        address,
        phone,
        tenantId,
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.message || 'Registration failed');
      } else {
        throw new Error('Network error or server is not responding');
      }
    }
  },

  logout() {
    localStorage.removeItem('user-token');
    localStorage.removeItem('user-roles');
    localStorage.removeItem('user-id');
    localStorage.removeItem('parent-id');
    localStorage.removeItem('role-ids');
    localStorage.removeItem('child-role-id');
  },

  getToken() {
    return localStorage.getItem('user-token');
  },

  isTokenValid(token) {
    if (!token) return false;
    const decoded = jwtDecode(token);
    return decoded.exp > Date.now() / 1000;
  },

  getUserId() {
    return localStorage.getItem('user-id');
  },

  getParentId() {
    return localStorage.getItem('parent-id');
  },

  getRoles() {
    const roles = localStorage.getItem('user-roles');
    return roles ? JSON.parse(roles) : [];
  },

  getRoleIds() {
    const roleIds = localStorage.getItem('role-ids');
    try {
      if (!roleIds) {
        console.warn('Role IDs not found in localStorage or are undefined.');
        return [];
      }

      const parsedRoleIds = JSON.parse(roleIds);
      if (!Array.isArray(parsedRoleIds)) {
        console.error('Parsed role IDs are not an array:', parsedRoleIds);
        return [];
      }

      return parsedRoleIds;
    } catch (error) {
      console.error('Error parsing role IDs from localStorage:', error.message);
      return [];
    }
  },

  async sendResetPasswordEmail(email, tenantId) {
    if (!email || !tenantId) {
      throw new Error('Email and tenant ID are required');
    }

    try {
      await API.post('auth/reset-password/send', { email, tenantId });
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(
          error.response.data.message || 'Failed to send reset password email'
        );
      } else {
        throw new Error('Network error or server is not responding');
      }
    }
  },

  resetPassword(token, password) {
    return axios.post('/api/auth/reset-password/reset', { token, password });
  },

  getChildRoleId() {
    return localStorage.getItem('child-role-id');
  },
};

export default AuthService;
